<template>
  <div class="__dashbody">
    <dashboardNav :haveBanner="false" />
    <div class="section">
        <div class="container-fluid">
			<div class="row">
				<div class="col-md-3">
					<div class="leftbar __msgleft">
                        <div role="tabpanel">
                            <!-- Nav tabs -->
                            <div class="__mesreq">
                                <ul class="nav nav-tabs __navtbs" role="tablist">
                                    <li role="presentation" class="active">
                                        <a href="#home" aria-controls="home" role="tab" data-toggle="tab">Messages</a>
                                    </li>
                                    <li role="presentation">
                                        <a href="#tab" aria-controls="tab" role="tab" data-toggle="tab">Requests</a>
                                    </li>
                                </ul>
                            </div>
                            
                            <img src="../assets/img/cross-out.svg" id="closebtn">
                            <!-- <i class="fa fa-times" id="closebtn"></i> -->
                            <!-- Tab panes -->
                            <div class="tab-content">
                                <div role="tabpanel" class="tab-pane active" id="home">
                                    <div class="__sidep __msgsidp">
                                        <div class="form-group">
                                            <input type="text" class="form-control __msgserch" placeholder="Search Conversation" id="">
                                        </div>
                                    </div>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>

                                    <a href=""><div class="__msgflxa">
                                        <img src="../assets/img/profimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>

                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/Ghana.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profimg5.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg1.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profimg7.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                </div>


                                <!-- request message -->
                                <div role="tabpanel" class="tab-pane" id="tab">
                                    <div class="__sidep __msgsidp">
                                        <div class="form-group">
                                            <input type="text" class="form-control __msgserch" placeholder="Search Conversation" id="">
                                        </div>
                                    </div>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>

                                    <a href=""><div class="__msgflxa">
                                        <img src="../assets/img/profimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>

                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/Ghana.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profimg5.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg1.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profimg7.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                    <a href=""><div class="__msgflx">
                                        <img src="../assets/img/profileimg.png" class="__msgimg">
                                        <div class="__msgdivp">
                                            <p class="__msgp">Carla Echevarria</p>
                                            <p class="__msgp1">Freelance Professional Model ...</p>
                                        </div>
                                        <p class="__msgp2">15:23</p>
                                    </div></a>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
				
				<div class="col-md-9">
					<section>
						<div class="__house">
                            <div class="" style="background: #ffffff;">
                                <div class="__msgous">
                                    <div class="__dashmchat">
                                        <div class="row">
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                <div class="__msgcfl">
                                                    <img src="../assets/img/profileimg.png" class="__msgcimg" style="margin-right: 10px;">
                                                    <div class="__chtp">
                                                        <p class="__out">There are are more than 80 Expert Networks specializing in everything from buy-side research to taxes and pharmaceuticals.  There are are more than 80 Expert Networks specializing in everything from buy-side research to taxes and pharmaceuticals.</p><span class="time-left">15:22</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4"></div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4"></div>
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                <div class="__msgcfl">
                                                    <div class="__chtp">
                                                        <p class="__in">There are are more than 80 Expert Networks specializing in everything from buy-side research to taxes and pharmaceuticals.  There are are more than 80 Expert Networks specializing in everything from buy-side research to taxes and pharmaceuticals.</p><span class="time-left">15:22</span>
                                                    </div>
                                                    <img src="../assets/img/profimg1.png" class="__msgcimg" style="margin-left: 10px;">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                <div class="__msgcfl">
                                                    <img src="../assets/img/profileimg.png" class="__msgcimg" style="margin-right: 10px;">
                                                    <div class="__chtp">
                                                        <p class="__out">There are are more than 80 Expert Networks specializing in everything from buy-side </p><span class="time-left">15:22</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4"></div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                <div class="__msgcfl">
                                                    <img src="../assets/img/profileimg.png" class="__msgcimg" style="margin-right: 10px;">
                                                    <div class="__chtp">
                                                        <p class="__out">There are are more than 80 Expert Networks specializing in everything from buy-side research to taxes and pharmaceuticals.  There are are more than 80 Expert Networks specializing in everything from buy-side research to taxes and pharmaceuticals.</p><span class="time-left">15:22</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4"></div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4"></div>
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                <div class="__msgcfl">
                                                    <div class="__chtp">
                                                        <p class="__in">There are are more than 80 Expert Networks specializing in everything from buy-side research to taxes and pharmaceuticals.  There are are more than 80 Expert Networks specializing in everything from buy-side research to taxes and pharmaceuticals.</p><span class="time-left">15:22</span>
                                                    </div>
                                                    <img src="../assets/img/profimg1.png" class="__msgcimg" style="margin-left: 10px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="__msghr">
                                <div class="__msgcol5r1">
                                    <form action="" method="POST" role="form">
                                        <div class="__foflx">
                                            <div class="__fodiv">
                                                <a href=""><img src="../assets/img/attachment.png" class="__foimg"></a>
                                            </div>
                                            <div style="width: 100%;">
                                                <div class="input-group" style="width: 100%">
                                                    <textarea class="form-control __inputactiv" placeholder="Type Your Message" rows="2"></textarea>

                                                    <div class="input-group-btn">
                                                        <button type="submit" class="btn __btnshow">Send</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
						</div>
					</section>
				</div>			
			</div>
		</div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";

import dashboardNav from "../components/dashboardNav.vue";
import Loader from "../components/loader";

export default {
  
  components: {
    dashboardNav,
    Loader,
  },

    mounted () {
		$('#openSidebar').click(function(){
        $('.leftbar').addClass('biger');
        });
        $('#closebtn').click(function(){
            $('.leftbar').removeClass('biger');
        });

        // when an input is active button show
        $(".__inputactiv").keyup(function () {
        if ($(this).val()) {
            $(".__btnshow").show();
        }
        else {
            $(".__btnshow").hide();
        }
        });
        $(".__btnshow").click(function () {
        $(".__inputactiv").val('');
        $(this).hide();
        });
	}
  
};
</script>

<style lang="scss">

</style>
